import { LivescoreWidgetJson } from './livescore-widget.json';
import LivescoreWidgetBuilder from './livescore-widget.builder';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { RefreshTimeValuesType } from '../../../../v2/partials/refresh-time-input/helper';

export default class LivescoreWidgetModel {
	readonly displayOdds: boolean = false;
	readonly date: string = '';
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly competitionIds: string[] = [];
	readonly headerDefaultOption: string | null;
	readonly refreshTime: RefreshTimeValuesType;
	readonly displayHeader: boolean = false;

	private constructor(
		displayOdds: boolean,
		date: string,
		bookmakers: SportOddsBookmakerModel[] | null,
		competitionIds: string[],
		headerDefaultOption: string | null,
		refreshTime: RefreshTimeValuesType,
		displayHeader: boolean,
	) {
		this.displayOdds = displayOdds;
		this.date = date;
		this.bookmakers = bookmakers;
		this.competitionIds = competitionIds;
		this.headerDefaultOption = headerDefaultOption;
		this.refreshTime = refreshTime;
		this.displayHeader = displayHeader;
	}

	toJson(): LivescoreWidgetJson {
		return {
			displayOdds: this.displayOdds,
			date: this.date,
			bookmakers: this.bookmakers,
			competitionIds: this.competitionIds,
			headerDefaultOption: this.headerDefaultOption,
			refreshTime: this.refreshTime,
			displayHeader: this.displayHeader,
		};
	}

	static fromJson(json: LivescoreWidgetJson): LivescoreWidgetModel {
		return new LivescoreWidgetModel(
			json.displayOdds,
			json.date,
			json.bookmakers,
			json.competitionIds,
			json.headerDefaultOption,
			json.refreshTime,
			json.displayHeader,
		);
	}

	static builder(livescoreWidget?: LivescoreWidgetModel): LivescoreWidgetBuilder {
		return new LivescoreWidgetBuilder(livescoreWidget);
	}
}
