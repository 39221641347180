import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompetitionFilterModal } from './competition-filter-modal.component';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import { CompetitionSelectInputValues } from './competition-select-input-values.component';
import { CountryCompetitions } from '../../../types/livescore';

interface Props {
	competitions: CountryCompetitions[];
	withAllSelectedCompetitions: boolean;
	allCompetitionIds: string[];
	competitionIds: string[];
	setCompetitionIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const CompetitionSelectInput: FC<Props> = ({
	competitions,
	withAllSelectedCompetitions,
	allCompetitionIds,
	competitionIds,
	setCompetitionIds,
}) => {
	const [t] = useTranslation();
	const [isModalOpen, setModalOpen] = useState(false);
	const [selectedCompetitionInputs, setSelectedCompetitionInputs] = useState<CountryCompetitions[]>([]);

	const toggleModal = () => setModalOpen(!isModalOpen);
	const handleOpenModal = () => setModalOpen(true);

	useEffect(() => {
		if (!competitions) {
			setSelectedCompetitionInputs([]);
			return;
		}

		const data = competitions
			.map((item) => ({
				...item,
				competitions:
					competitionIds && competitionIds.length > 0
						? item.competitions.filter((competition) => competitionIds.includes(competition.value))
						: [],
			}))
			.filter((item) => item.competitions.length > 0);
		setSelectedCompetitionInputs(data);
	}, [competitionIds, competitions]);

	useEffect(() => {
		if (!competitionIds) {
			setCompetitionIds(allCompetitionIds);
		}
	}, [competitionIds]);

	return (
		<>
			<Row>
				<Col>
					<Label htmlFor='competition-input'>{t('tournament_input.label')}</Label>
					<Select
						isMulti={true}
						placeholder={t('tournament_input.placeholder')}
						isSearchable={false}
						menuIsOpen={false}
						onMenuOpen={handleOpenModal}
						className={competitionIds && competitionIds.length === 0 ? 'invalid-field' : ''}
						id={'competition-input'}
					/>
					{competitionIds && competitionIds.length === 0 ? (
						<div id={'competition-input-error'} className='error-message'>
							<i id={'competition-input-error-icon'} className='fa-solid fa-circle-exclamation' />
							<span id={'competition-input-error-text'}>{t('tournament_input.error')}</span>
						</div>
					) : null}
				</Col>
			</Row>
			<div
				id='selected-competitions-container'
				className={competitionIds && competitionIds.length === 0 ? 'hide-competitions-container' : 'show-competitions-container'}
			>
				{withAllSelectedCompetitions ? (
					<Col>
						<Label htmlFor='all-competitions-input' className='selected-input-label'>
							{t('tournament_input.allCompetitionsLabel')}
						</Label>
						<Select
							isMulti={true}
							isSearchable={false}
							menuIsOpen={false}
							value={{ label: t('tournament_input.allCompetitionsDefaultValue'), value: 'All Leagues' }}
							isClearable
							onChange={() => {
								setCompetitionIds([]);
							}}
							components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
							className='selected-input'
							id='all-competitions-input'
						/>
					</Col>
				) : (
					selectedCompetitionInputs.map((opt, index) => (
						<CompetitionSelectInputValues key={index} {...opt} competitionIds={competitionIds} setCompetitionIds={setCompetitionIds} />
					))
				)}
			</div>
			<CompetitionFilterModal
				competitions={competitions}
				isModalOpen={isModalOpen}
				toggleModal={toggleModal}
				withAllCompetitions={withAllSelectedCompetitions}
				competitionIds={competitionIds ? competitionIds : allCompetitionIds}
				setCompetitionIds={setCompetitionIds}
			/>
		</>
	);
};
