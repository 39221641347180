import { useEffect, useState } from 'react';
import MultisportHttpService from '../../../../../../../services/rest/multisport-http-service';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { CompetitionResponse, CountryCompetitions } from '../../../types/livescore';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store/store';
import { sortCompetitionsByCountry } from '../sortCompetitionsByCountry';
import { useTranslation } from 'react-i18next';

const getPreviewCompetitions = ({ competitionIds, competitions }: { competitionIds: string[]; competitions: CountryCompetitions[] }) => {
	if (!competitionIds) return [];
	return competitions
		.map(({ country, competitions }) => ({
			name: country,
			competitions: competitions.filter(({ value }) => competitionIds.includes(value)),
		}))
		.filter(({ competitions }) => competitions.length !== 0);
};

interface Props {
	competitionIds: string[];
}

export const usePreviewCompetitions = ({ competitionIds }: Props) => {
	const [t] = useTranslation();
	const [competitions, setCompetitions] = useState<CountryCompetitions[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const language = useSelector((state: AppState) => state.project.currentProject.language);

	const getCompetitions = (sport: string, lang: string) => {
		MultisportHttpService.getCompetitionListing(sport, lang)
			.then((response) => {
				const filteredCompetitionsBySport = response.data.competitions
					.filter(({ competition }: { competition: CompetitionResponse }) => competition.sport === SportTypes.ICE_HOCKEY)
					.map(({ competition }: { competition: CompetitionResponse }) => competition);

				const data = sortCompetitionsByCountry(filteredCompetitionsBySport, t);
				data && setCompetitions(data);
				setIsLoading(false);
			})
			.catch((e: any) => e);
	};

	useEffect(() => {
		getCompetitions(SportTypes.ICE_HOCKEY, language);
	}, []);

	const allCompetitionIds = competitions.flatMap(({ competitions }) => competitions.map(({ value }) => value));
	const filteredCompetitionIds = allCompetitionIds && competitionIds && allCompetitionIds.filter((id) => competitionIds.includes(id));
	const showAllCompetitions = allCompetitionIds && competitionIds && allCompetitionIds.length === competitionIds.length;
	const data = getPreviewCompetitions({ competitionIds: showAllCompetitions ? allCompetitionIds : filteredCompetitionIds, competitions });

	return { competitions: data, showAllCompetitions, isLoading };
};
