import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label } from 'reactstrap';
import { Competition } from '../../../types/livescore';

interface Props {
	country: string;
	competitions: Competition[];
	competitionIds: string[];
	setCompetitionIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const CompetitionSelectInputValues: FC<Props> = ({ country, competitions, competitionIds, setCompetitionIds }) => {
	const valueIds = competitions && competitions.length > 0 ? competitions.map(({ value }) => value) : [];
	const values = competitions && competitions.length > 0 ? competitions.map(({ label, value }) => ({ label, value })) : [];
	const [selectedValues, setSelectedValues] = useState(values);

	// types are left as any because it forces me to change a lot of logic in parent components
	const onChange = (_: any, actionMeta: any) => {
		const { action, removedValue } = actionMeta;
		if (action === 'clear') {
			const filteredCompetitionIds = competitionIds.filter((id) => !valueIds.includes(id));
			setCompetitionIds(filteredCompetitionIds);
			setSelectedValues([]);
		} else {
			const value = removedValue.value;
			const filteredCompetitionIds = competitionIds.filter((id) => id !== value);
			const filteredSelectedValues = values.filter((opt) => opt.value !== value);

			setCompetitionIds(filteredCompetitionIds);
			setSelectedValues(filteredSelectedValues);
		}
	};

	useEffect(() => {
		const values = competitions && competitions.length > 0 ? competitions.map(({ label, value }) => ({ label, value })) : [];
		setSelectedValues(values);
	}, [competitions]);

	return (
		<Col>
			<Label htmlFor={`${country}-competitions-input`} className='selected-input-label'>
				{country}
			</Label>
			<Select
				isMulti={true}
				isSearchable={false}
				menuIsOpen={false}
				value={selectedValues}
				isClearable
				onChange={onChange}
				components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
				className='selected-input'
				id={`${country}-competitions-input`}
			/>
		</Col>
	);
};
